import { css } from "@nfront/global-styles";

const globalCSS = css`
  @font-face {
    font-display: swap;
    font-family: "TT Hoves";
    font-style: normal;
    font-weight: 400;
    src: local("TT Hoves"),
      url("/fonts/tt-hoves-regular.woff") format("truetype");
  }

  @font-face {
    font-display: swap;
    font-family: "TT Hoves";
    font-style: normal;
    font-weight: 500;
    src: local("TT Hoves"),
      url("/fonts/tt-hoves-medium.woff") format("truetype");
  }

  [style*="--aspect-ratio"] > :first-child {
    width: 100%;
  }

  [style*="--aspect-ratio"] > img {
    height: auto;
  }

  @supports (--custom: property) {
    [style*="--aspect-ratio"] {
      position: relative;
    }
    [style*="--aspect-ratio"]::before {
      height: 0;
      content: "";
      display: block;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
    }
    [style*="--aspect-ratio"] > :first-child {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }

  html,
  body {
    line-height: ${(p) => p.theme.textStyle.defaultStyles.lineHeight};
    font-family: ${(p) => p.theme.fontFamily.fontFamilies.primary};
    color: ${(p) => p.theme.color.colors.dark.value};
    background-color: ${(p) => p.theme.color.colors.light.value};
    min-height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
  }

  * {
    box-sizing: border-box;
  }
  a {
    color: inherit;
    text-decoration: none;
  }

  em {
    font-style: italic;
  }

  h1 {
    margin: 0;
    font-size: unset;
  }

  table.table {
    border-spacing: 2px;
    border-collapse: separate;
  }

  .table th,
  .table td {
    padding: 7px;
    font-size: 0.9em;
    vertical-align: middle;
  }

  .table th {
    backdrop-filter: blur(20px) brightness(70%);
  }

  .table td {
    backdrop-filter: blur(20px) brightness(85%);
  }

  .slider-swiper-pagination-custom {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .swiper-pagination-bullet-custom {
    background-color: #00132a66;
    border: 1px solid #ffffff33;
    border-radius: 999px;
    height: 8px;
    width: 8px;
    cursor: pointer;
    margin-right: 0.5rem;

    &:hover {
      background-color: #18264e;
    }

    & > span {
      display: none;
    }
  }

  .swiper-pagination-bullet-active-custom {
    background-color: #3cc3dd;
    border-color: #3cc3dd;
  }
`;

export default globalCSS;
