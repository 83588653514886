module.exports = {
  id: "global-theme",

  color: {
    colors: {
      light: { value: "#ffffff" },
      dark: { value: "#101010" },

      overlay: {
        type: "RGB",
        value: ["16", "16", "16"],
        opacity: "0.5"
      }
    }
  },

  fontFamily: {
    fontFamilies: {
      primary: "'TT Hoves', sans-serif"
    }
  },

  mediaquery: {
    description: "",
    mediaqueries: [
      {
        label: "default",
        minWidth: null,
        container: "95%",
        sideMargin: "100px"
      },
      {
        label: "s",
        minWidth: "600px",
        container: "552px",
        sideMargin: "100px"
      },
      {
        label: "m",
        minWidth: "768px",
        container: "720px",
        sideMargin: "100px"
      },
      {
        label: "l",
        minWidth: "1068px",
        container: "1020px",
        sideMargin: "100px"
      },
      {
        label: "xl",
        minWidth: "1216px",
        container: "1020px",
        sideMargin: "100px"
      },
      {
        label: "xxl",
        minWidth: "1542px",
        container: "1020px",
        sideMargin: "100px"
      },
      {
        label: "xxxl",
        minWidth: "1680px",
        container: "1020px",
        sideMargin: "100px"
      }
    ]
  },

  fontWeight: {
    thin: "100",
    light: "300",
    normal: "400",
    bold: "700",
    extraBold: "800"
  },

  lineHeight: {
    s: 1,
    m: 1.2,
    l: 1.5,
    xl: 3
  },

  textStyle: {
    defaultStyles: {
      fontWeight: "400",
      lineHeight: "1.4",
      letterSpacing: "0px",
      fontSize: "10px",
      fontFamily: "primary"
    },
    textStyles: [
      {
        name: "heroBig",
        fontFamily: "primary",
        fontSize: "38px",
        lineHeight: "100%",
        fontWeight: "700",
        letterSpacing: "-1.8px",
        responsive: [
          {
            breakpoint: "m",
            fontSize: "48px",
            lineHeight: "100%"
          },
          {
            breakpoint: "l",
            fontSize: "64px",
            lineHeight: "62.5px"
          }
        ]
      },
      {
        name: "h1",
        fontFamily: "primary",
        fontSize: "36px",
        lineHeight: "36px",
        fontWeight: "500",
        letterSpacing: "-0.8px",
        responsive: [
          {
            breakpoint: "l",
            fontSize: "48px",
            lineHeight: "100%"
          }
        ]
      },
      {
        name: "h2",
        fontFamily: "primary",
        fontSize: "24px",
        lineHeight: "105%",
        fontWeight: "500",
        letterSpacing: "-0.5px",
        responsive: [
          {
            breakpoint: "m",
            fontSize: "30px"
          },
          {
            breakpoint: "l",
            fontSize: "40px"
          }
        ]
      },
      {
        name: "h3",
        fontFamily: "primary",
        fontSize: "21px",
        lineHeight: "110%",
        fontWeight: "500",
        letterSpacing: "-0.8px",
        responsive: [
          {
            breakpoint: "l",
            fontSize: "28px"
          }
        ]
      },
      {
        name: "h4",
        fontFamily: "primary",
        fontSize: "18px",
        lineHeight: "110%",
        fontWeight: "500",
        letterSpacing: "-0.4px",
        responsive: [
          {
            breakpoint: "l",
            fontSize: "24px"
          }
        ]
      },
      {
        name: "paragraph",
        fontFamily: "primary",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "400",
        letterSpacing: "0px",
        responsive: [
          {
            breakpoint: "l",
            fontSize: "18px",
            lineHeight: "144%"
          }
        ]
      },
      {
        name: "button",
        fontSize: "15px",
        lineHeight: "24px",
        fontWeight: "500",
        letterSpacing: "0.3px",
        fontFamily: "primary",
        croptop: 3,
        cropbottom: 3,
        responsive: []
      },
      {
        name: "small",
        fontFamily: "primary",
        fontSize: "13px",
        lineHeight: "1rem",
        fontWeight: "400",
        letterSpacing: "0px",
        responsive: []
      }
    ]
  },

  gradient: {
    description: "",
    gradients: {
      gradient1: {
        direction: "180deg",
        stops: [
          {
            color: "#3CC3DD",
            opacity: 0.7,
            position: "0%"
          },
          {
            color: "#A950CF",
            opacity: 0.7,
            position: "100%"
          }
        ]
      },

      gradient2: {
        direction: "180deg",
        stops: [
          {
            color: "#19E99E",
            opacity: 0.7,
            position: "0%"
          },
          {
            color: "#3CC3DD",
            opacity: 0.7,
            position: "100%"
          }
        ]
      },

      gradient3: {
        direction: "180deg",
        stops: [
          {
            color: "#A950CF",
            opacity: 0.7,
            position: "0%"
          },
          {
            color: "#FA0067",
            opacity: 0.7,
            position: "100%"
          }
        ]
      },

      gradient4: {
        direction: "180deg",
        stops: [
          {
            color: "#FA0067",
            opacity: 0.7,
            position: "0%"
          },
          {
            color: "#FBE15C",
            opacity: 0.7,
            position: "100%"
          }
        ]
      },

      gradient5: {
        direction: "180deg",
        stops: [
          {
            color: "#FBE15C",
            opacity: 0.7,
            position: "0%"
          },
          {
            color: "#3AC7D3",
            opacity: 0.7,
            position: "100%"
          }
        ]
      },

      borderGradient: {
        direction: "180deg",
        stops: [
          {
            color: "#EEEEEE",
            opacity: 0.7,
            position: "0%"
          },
          {
            color: "#DCDDDE",
            opacity: 0,
            position: "40%"
          }
        ]
      }
    }
  },

  shadow: {
    description: "",
    shadows: {
      small: {
        x: 0,
        y: 10,
        blur: 19,
        color: "#000000",
        opacity: 0.15
      },
      medium: {
        x: 0,
        y: 0,
        blur: 10,
        color: "#000000",
        opacity: 0.45
      },
      big: {
        x: 0,
        y: 0,
        blur: 20,
        color: "#000000",
        opacity: 0.5
      }
    }
  },

  timming: {
    xs: "0.12s",
    s: "0.25s",
    m: "0.5s",
    l: "1s",
    hover: "0.25s",
    stickyNavigation: "0.8s",
    moduleFadeIn: "0.66s",
    moduleTranslate: "24px"
  },

  radii: {
    s: "4px",
    m: "10px",
    l: "24px",
    button: "4px",
    circle: "999px"
  },

  line: {
    s: "1px",
    m: "2px",
    l: "4px",
    button: "1px"
  },

  spacing: {
    xxxs: "4px",
    xxs: "8px",
    xs: "16px",
    s: "24px",
    m: "32px",
    l: "48px",
    xl: "56px",
    xxl: "64px",
    xxxl: "80px",
    buttonM: {
      minWidth: "0px",
      minHeight: "0px",
      paddingX: "24px",
      paddingY: "14px",
      iconSize: "18px",
      ladding: "32px"
    },
    buttonS: {
      minWidth: "184px",
      minHeight: "32px",
      paddingX: "8px",
      paddingY: "12px",
      ladding: "32px",
      iconSize: "14px"
    },
    buttonFeatured: {
      minWidth: "350px",
      minHeight: "56px",
      paddingX: "24px",
      paddingY: "24px",
      ladding: "32px",
      iconSize: "24px"
    }
  }
};
