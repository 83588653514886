import { createGlobalStyle } from "@nfront/global-styles";

import reset from "./reset";
import normalize from "./normalize";
import globalCSS from "./globalCSS";

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${normalize}
  ${globalCSS}
`;

export default GlobalStyle;
