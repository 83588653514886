import React, { createContext, useContext } from "react";
import { ThemeProvider } from "styled-components";

import { createGlobalTheme, createTheme } from "@themes/utils";

const StyleContext = createContext({});

const StyleProvider = (props) => {
  const { children } = props;

  const globalTheme = createGlobalTheme();

  globalTheme.mediaquery.mediaqueries.forEach((mq) => {
    globalTheme.breakpoints[mq.label] = mq.minWidth;
  });

  const activeTheme = {
    ...createTheme({ theme: "default-theme" })
  };

  if (activeTheme.mediaquery) {
    activeTheme.mediaquery.mediaqueries.forEach((mq) => {
      activeTheme.breakpoints[mq.label] = mq.minWidth;
    });
  }

  return (
    <StyleContext.Provider value={{ globalTheme }}>
      <ThemeProvider theme={{ ...globalTheme, ...activeTheme }}>
        {children}
      </ThemeProvider>
    </StyleContext.Provider>
  );
};

const useStyle = () => {
  return useContext(StyleContext);
};

const StyleConsumer = StyleContext.Consumer;

export { useStyle, StyleProvider, StyleConsumer, StyleContext };
