import React, { Suspense } from "react";

import { StyleProvider } from "@contexts";
// import { languages } from "./src/config";

// export const onClientEntry = () => {
//   const userLang = navigator.language.slice(0, 2);

//   const { pathname } = window.location;

//   if (
//     userLang !== languages.default &&
//     !pathname.startsWith(languages.paths[languages.notAvailableLangDefault])
//   ) {
//     const newPath = `${
//       languages.paths[languages.notAvailableLangDefault]
//     }${pathname}`;
//     window.location.pathname = newPath;
//   }
// };

export const wrapRootElement = ({ element }) => (
  <StyleProvider>
    <Suspense fallback={null}>{element}</Suspense>
  </StyleProvider>
);
